import Layout from "../../components/layout"
import React from "react"
import SEO from "../../components/seo"
import * as css from "./kindergarden-staff.module.css"

function KindergardenStaff() {
  return (
    <Layout menuType="kindergarden">
      <SEO title="Pracownicy przedszkola - ZSP Nidek" />
      <h1>Pracownicy Przedszkola</h1>
      <table className={css.table}>
        <tr>
          <td>Dyrektor</td> <td>mgr Ewa Snażyk - Płonka</td>
        </tr>
        <tr>
          <td>Wychowanie przedszkolne grupa 1</td>
          <td>mgr Krystyna Chmiel</td>
        </tr>
        <tr>
          <td>Wychowanie przedszkolne grupa 2</td>
          <td>mgr Aleksandra Saferna</td>
        </tr>
        <tr>
          <td>Wychowanie przedszkolne grupa 3</td>
          <td>mgr Marzena Noworyta</td>
        </tr>
        <tr>
          <td>Wychowanie przedszkolne</td> <td>mgr Ewelina Skibińska</td>
        </tr>
        <tr>
          <td>Pedagog Specjalny</td>
          <td>
            mgr Marzena Noworyta
            <br />
            mgr Ewelina Skibińska
          </td>
        </tr>
        <tr>
          <td>Oligofrenopedagog/Logopeda/SI</td>
          <td>mgr Ewa Snażyk-Płonka</td>
        </tr>
        <tr>
          <td>Katecheta</td> <td>mgr Anna Karcz</td>
        </tr>
        <tr>
          <td>Język angielski</td> <td>mgr Anna Wnęk</td>
        </tr>
      </table>
    </Layout>
  )
}

export default KindergardenStaff
